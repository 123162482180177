import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
import { SEO } from "gatsby-theme-catalyst-core";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="404: Not found" mdxType="SEO" />
    <h1 {...{
      "id": "not-found"
    }}>{`NOT FOUND`}</h1>
    <p>{`Oh the sadness. You hit a page that does not exist!`}</p>
    <p>{`Tap your heels three times and `}<a parentName="p" {...{
        "href": "https://www.erichowey.dev/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`head back home`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      